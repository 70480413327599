var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data Studi Kasus ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"nisn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siswa","label-for":"nisn","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"nisn","reduce":function (nisn) { return nisn.nisn; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name"},model:{value:(_vm.dataParent.nisn),callback:function ($$v) {_vm.$set(_vm.dataParent, "nisn", $$v)},expression:"dataParent.nisn"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"21-05-1992"},model:{value:(_vm.dataParent.date),callback:function ($$v) {_vm.$set(_vm.dataParent, "date", $$v)},expression:"dataParent.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gejala Masalah","label-for":"problem"}},[_c('validation-provider',{attrs:{"name":"Masalah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"problem","state":errors.length > 0 ? false : null,"placeholder":"Masalah"},model:{value:(_vm.dataParent.problem),callback:function ($$v) {_vm.$set(_vm.dataParent, "problem", $$v)},expression:"dataParent.problem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"background","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Latar Belakang","label-for":"background","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"background","reduce":function (background) { return background.real_id; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listBackground,"label":"background_desc"},model:{value:(_vm.dataParent.background),callback:function ($$v) {_vm.$set(_vm.dataParent, "background", $$v)},expression:"dataParent.background"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Perkiraan Masalah","label-for":"estimated_problem"}},[_c('validation-provider',{attrs:{"name":"estimated_problem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"estimated_problem","state":errors.length > 0 ? false : null,"placeholder":"Perkiraan Masalah"},model:{value:(_vm.dataParent.estimated_problem),callback:function ($$v) {_vm.$set(_vm.dataParent, "estimated_problem", $$v)},expression:"dataParent.estimated_problem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Penanganan","label-for":"handling"}},[_c('validation-provider',{attrs:{"name":"Penanganan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"handling","state":errors.length > 0 ? false : null,"placeholder":"Penanganan"},model:{value:(_vm.dataParent.handling),callback:function ($$v) {_vm.$set(_vm.dataParent, "handling", $$v)},expression:"dataParent.handling"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tindak Lanjut","label-for":"follow_up"}},[_c('validation-provider',{attrs:{"name":"follow_up","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"follow_up","state":errors.length > 0 ? false : null,"placeholder":"Tindak Lanjut"},model:{value:(_vm.dataParent.follow_up),callback:function ($$v) {_vm.$set(_vm.dataParent, "follow_up", $$v)},expression:"dataParent.follow_up"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"bk_teacher","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Guru BK","label-for":"bk_teacher","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"bk_teacher","reduce":function (bk_teacher) { return bk_teacher.id; },"placeholder":"Pilih Guru BK","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listGuru,"label":"name"},model:{value:(_vm.dataParent.bk_teacher),callback:function ($$v) {_vm.$set(_vm.dataParent, "bk_teacher", $$v)},expression:"dataParent.bk_teacher"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }